.botao-servicos {
    background-color: #DA55B6;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  
  .botao-servicos:hover {
    background-color: #9D13DB;
  }
  
  
  
  .botao-servicos {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Adicionando margem entre os botões */
  .botao-servicos {
    margin-left: 10px;
  }
  
  /* Sugestões de texto */
  
  .botao-servicos::before {
    content: '\2022'; /* Adiciona um ponto de bala antes do texto */
    margin-right: 5px;
  }
  
  .botao-servicos::after {
    content: '\2022'; /* Adiciona um ponto de bala após o texto */
    margin-left: 5px;
  }
  