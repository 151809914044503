/* Banner.css */

.banner {
  background-color: #20065F; /* Cor de fundo do banner */
  padding: 40px 20px; /* Aumentei o espaçamento interno para dar mais destaque ao conteúdo */
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.logo-img-div {
  display: flex;
  justify-content: center; /* Centraliza a logo no eixo horizontal */
  align-items: center;
  width: 100%;
  margin-bottom: 20px; /* Adicionei margem inferior para separar a logo do conteúdo */
}

.logo-img-div img {
  max-width: 120px; /* Garante que a imagem não ultrapasse a largura do contêiner */
  height: auto; /* Mantém a proporção da imagem */
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.content-wrapper {
  display: flex;
  flex-direction: column; /* Empilha os elementos verticalmente */
  align-items: center; /* Centraliza o conteúdo na direção vertical */
}

.text-block {
  padding: 20px;
}

.text-block h1, .text-block p {
  margin: 0;
}

.text-block h1 {
  font-size: 2.5em; /* Aumentei o tamanho da fonte para destacar o título principal */
  margin-bottom: 10px; /* Adicionei espaço entre o título e o parágrafo */
  color: #fff; /* Transformei o título em branco */
}

.text-block p {
  font-size: 1.2em; /* Adicionei um tamanho de fonte menor para os parágrafos */
  color: #fff; /* Transformei o parágrafo em branco */
}

@media (max-width: 768px) {
  /* Estilos para telas menores (até 768px) */
  .logo-img-div img {
    width: 80px; /* Ajuste a largura da imagem para telas menores */
  }

  .text-block h1 {
    font-size: 2em; /* Ajuste o tamanho do título para telas menores */
    margin-bottom: 8px; /* Reduzi o espaço entre o título e o parágrafo em telas menores */
  }

  .text-block p {
    font-size: 1em; /* Ajuste o tamanho do texto para telas menores */
  }
}
