/* Estilos para o formulário */
.formulario {
  display: flex;
  justify-content: center;
  align-items: center; /* centralizar verticalmente */
  min-height: 100vh; /* altura mínima da viewport */
  margin: 0; /* remova a margem para cobrir toda a largura */
  background-color: #dfe1f8; /* cor de fundo para cobrir a largura */
}

.formulario form {
  width: 100%;
  max-width: 800px;
  background-color: #dfe1f8;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin: 20px; /* afaste o formulário das bordas da página */
}

/* Adicionando fundo mais extenso para telas menores (xs e sm) */
@media (max-width: 767px) {
  .formulario form {
    background-color: #dfe1f8; /* ou qualquer cor desejada */
    padding: 20px; /* ou qualquer valor desejado */
    border-radius: 0; /* remova a borda arredondada para ocupar toda a largura */
    margin: 0; /* remova a margem para cobrir toda a largura */
  }
  .botao-centralizado {
    display: flex;
    justify-content: center; /* centralizar horizontalmente */
    text-align: center;
    margin: 40px;
  }
  .campo-cartao {
    margin-bottom: 15px; /* reduza a margem entre os campos para telas menores */
  }
}

/* Estilo responsivo para telas médias (a partir de 768px de largura) */
@media (min-width: 768px) {
  .formulario form {
    width: 60%;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 2px 4px;
    margin: 20px; /* afaste o formulário das bordas da página */
  }

  .campo-cartao {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }

  .botao-centralizado {
    display: flex;
    justify-content: center; /* centralizar horizontalmente */
    text-align: center;
    margin: 40px;
  }

  .padding-form {
    padding: 10px;
  }
}

/* Restante do código permanece o mesmo */
