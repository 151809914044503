.time {
    text-align: center;
    padding: 32px;
}

.time h3 {
    font-size: 32px;
    border-bottom: 4px solid;
    display: inline-block;
    padding-bottom: 8px;
}

.time .colaboradores {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
    flex-wrap: wrap;
}