/* SobreNos.css */

.sobre-nos {
  background: #120052; /* Substitua "lightblue" pelo nome da cor desejada */
  padding: 80px 0;
  text-align: center;
  position: relative;
  }
  
  .sobre-nos-container {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    align-items: center;
  }
  
  .sobre-nos-conteudo {
    flex: 1;
    text-align: left;
    padding: 20px;
  }
  
  .sobre-nos-titulo {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-decoration: underline;
    text-underline-position: under;
    color: white
  }
  
  .sobre-nos-descricao {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
    color: white;
  }
  
  .sobre-nos-descricao:last-child {
    margin-bottom: 0;
  }
  
  /* Estilos responsivos para dispositivos menores */
  /* Estilos responsivos para dispositivos menores */

  @media (max-width: 767px) {
    .sobre-nos-container {
      flex-direction: column; /* Stack the elements vertically */
      align-items: center; /* Centralize os elementos verticalmente */
    }
  
    .sobre-nos-imagem {
        width: 100%; /* Faça a imagem ocupar a largura total */
        max-width: 100%; /* Defina a largura máxima como 100% para garantir responsividade */
        height: auto; /* Deixe a altura da imagem ajustar automaticamente de acordo com a largura */
        margin: 0; /* Remova a margem para evitar problemas de layout */
      }
  
    .sobre-nos-conteudo {
      text-align: center; /* Centralize o texto */
    }
  }
  