.campo-texto {
    margin: 24px 0;
}

.campo-texto label {
    display: block;
    margin-bottom: 8px;
    font-size: 24px;
}

.campo-texto input {
    background-color: #FFF;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
    width: 100%;
    border: none;
    font-size: 24px;
    padding: 24px;
    box-sizing: border-box;
}