/* Footer.css */

.footer {
    background-color: #20065F;
    text-align: center;
    padding: 24px;
    margin: 0;
    color: white;
}

.footer-content {
    display: flex;
    flex-wrap: wrap; /* Permite que os elementos quebrem para a próxima linha em telas menores */
    justify-content: center; /* Centraliza os itens no eixo horizontal */
}

.box-footer1,
.box-footer2 {
    width: 100%; /* Garante que cada caixa ocupe a largura total */
    text-align: center; /* Centraliza o conteúdo */
    margin-bottom: 10px;
}

.box-footer1 h2,
.box-footer2 h2 {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center; /* Centraliza os ícones e o texto */
    flex-wrap: wrap; /* Permite que os ícones quebrem para a próxima linha em telas menores */
}

.box-footer1 a,
.box-footer2 a {
    text-decoration: none;
    color: white;
    display: block; /* Garante que o link ocupe a largura total da caixa */
}

.box-footer1 h2 > * + *,
.box-footer2 h2 > * + * {
    margin-left: 10px;
    /* Espaço entre o ícone e o texto */
}

/* Estilo responsivo para telas menores (exemplo: até 768px de largura) */
@media (max-width: 768px) {
    .box-footer1 h2 > * + *,
    .box-footer2 h2 > * + * {
        margin-left: 0; /* Remove o espaço entre o ícone e o texto em telas menores */
        margin-top: 5px; /* Adiciona espaço entre ícone e texto */
    }
}
