.headerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  /* Adicionado um padding de 20px */
  margin: 20px 0;
  /* Adicionado margin para espaçamento superior e inferior */
}

.cta-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 30px;
}

.whatsapp-button {
  background-color: #4ADEDD;
  color: white;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: bold;
  white-space: nowrap;
  font-size: 30px;
  /* Adicionado o ponto e vírgula no final da linha */
}

/* Limita o texto a 8 colunas de grid */
.colaborador-text {
  max-width: 60%;
  text-align: center;
}

.colaborador-text {
  max-width: 60%;
  text-align: center;
}

@media (max-width: 767px) {
  .colaborador-text {
    max-width: 90%;
    text-align: center;
  }
}